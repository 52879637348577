<template>
  <div id="toast">
        <span class="loading" :style="{ top: styleTop }">
          <div class="loading-spinner"></div>
          <div>加载中...</div>
        </span>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      styleTop: "-100px",
    };
  },

  mounted() {
    this.$bus.$on('show', () => {
      console.log(111)
      this.isLoading = true;
      this.styleTop = "30px"
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", () => {
      }, true);
    });
    this.$bus.$on('close', () => {
      this.isLoading = false;
      this.styleTop = "-100px"
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = '';
      document.removeEventListener("touchmove", mo, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off('show');
    this.$bus.$off('close');
  }
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 10px;
  border-radius: 3px;
  z-index: 99999;
  width: fit-content;
  height: fit-content;
  transition: all 0.35s;
  color: rgb(121, 187, 255);
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 0.8rem;

  .loading-spinner {
    width: 10px;
    height: 10px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top-color: rgb(121, 187, 255);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>